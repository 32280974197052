import currency from "currency.js";
import {isValidZipCode} from "kistenkonfigurator/build/util/string-util";
import * as _ from "lodash";
import {CHF, CHF_ZERO} from "kistenkonfigurator/build/util/currency-util";
import {Context} from "@nuxt/types";
import {ActionContext} from "vuex";
import {chain} from "lodash";
import {
  CartItemMutation,
  generateLocalStoreId,
  getCartItemKey,
  LocalCartItem,
  ProductItem
} from "../model/cart/LocalCartItem";
import {CartBusinessLogic} from "../model/CartBusinessLogic";
import {RestApi} from "../model/rest/Api";
import {ProductRestApi} from "../model/graphql/ProductRestApi";

export const LOCAL_STORAGE_SHIPPING_ZIP_CODE = "shippingZipCode";

interface State {
  list: Array<LocalCartItem>;
  /**
   * Discount this customer has
   */
  discount: number,

  /**
   * Destination zip code
   */
  shippingZipCode: string;

  /**
   * Delivery cost
   * @type {currency}
   */
  shippingCost: currency,
}

export const state = () => ({
  list: [],
  /**
   * Discount this customer has
   */
  discount: 0,

  /**
   * Destination zip code
   */
  shippingZipCode: "",

  /**
   * Delivery cost
   * @type {currency}
   */
  shippingCost: CHF_ZERO,
} as State);

export const mutations = {
  /**
   * @param state
   * @param {CartItem} item
   */
  add(state: State, item: LocalCartItem) {
    if (item.pricePerOne == null) {
      item.pricePerOne = CHF_ZERO;
    }

    state.list.push(item);
    _updateLocalStorage(item);
  },

  setCustomerRef(_: unknown, {
    item,
    customerRef,
  }: { item: LocalCartItem, customerRef: string }) {
    item.customerRef = customerRef;
    _updateLocalStorage(item);
  },

  // addWithoutStore(state, item) {
  //     state.list.push(item);
  // },

  updateItem(this: { _vm: Vue }, _: any, payload: { item: LocalCartItem, quantity: number, pricePerOne: currency }) {
    const item = payload.item;
    if (payload.quantity != null) {
      this._vm.$set(item, "quantity", payload.quantity);
    }
    if (payload.pricePerOne != null) {
      this._vm.$set(item, "pricePerOne", payload.pricePerOne);
    }
    _updateLocalStorage(item);
  },
  removeItem(state: State, item: LocalCartItem) {
    const index = _findItem(state.list, item);

    state.list.splice(index, 1);
    if (process.browser) {
      localStorage.removeItem(generateLocalStoreId(item));
    }
  },

  clear(state: State) {
    state.list.forEach((/** ProductItem */ item) => {
      if (process.browser) {
        localStorage.removeItem(generateLocalStoreId(item));
      }
    });
    state.list = [];
  },

  setDiscount(state: State, newDiscount: number) {
    state.discount = newDiscount;
  },

  setShippingCost(state: State, deliveryCost: currency) {
    state.shippingCost = deliveryCost;
  },

  setShippingZipCode(state: State, zipCode: string) {
    state.shippingZipCode = zipCode;
    state.shippingCost = CHF_ZERO;
    if (process.browser) {
      localStorage.setItem(LOCAL_STORAGE_SHIPPING_ZIP_CODE, zipCode);
    }
  },
};

export const actions = {
  addFromLocalStore(context: ActionContext<State, any>, items: Array<any>): Promise<void> {
    const checkedItems: Array<LocalCartItem> = chain(items)
      .filter(it => "product" in it && "quantity" in it && "configuration" in it)
      .map((it) => {
        if ("pricePerOne" in it) {
          it.pricePerOne = CHF(it.pricePerOne);
        }
        if (!("mutability" in it)) {
          it.mutability = CartItemMutation.ALL;
        }
        return it as LocalCartItem;
      })
      .value();

    return context.dispatch("add", checkedItems);
  },
  async add({commit, state, dispatch}: ActionContext<State, any>, items: Array<ProductItem>) {
    items
      .forEach((item) => {
        const index = _findItem(state.list, item);

        if (index <= -1) {
          commit("add", item);
          return;
        }

        /** @type {CartItem} */
        const existingItem = state.list[index];
        const newQuantity = existingItem.quantity + item.quantity;

        commit("updateItem", {
          item: existingItem,
          quantity: newQuantity,
          pricePerOne: existingItem.pricePerOne,
          productImageUrl: existingItem.productImageUrl,
        });
      });

    return await dispatch("updatePrices");
  },

  async updatePrices({commit, state}: ActionContext<State, any>) {
    const orderPrice = await _getApi(this as any).getPriceMultipleItem(state.list, false, state.shippingZipCode);

    state.list.forEach((item) => {
      const cartItemKey = getCartItemKey(item);

      const priceFromServer = orderPrice.getItem(cartItemKey);

      item.pricePerOne = priceFromServer != null && priceFromServer.pricePerOne != null
        ? CHF(priceFromServer.pricePerOne)
        : CHF_ZERO;

      const index = _findItem(state.list, item);

      /** @type {CartItem} */
      const existingItem = state.list[index];

      commit("updateItem", {
        item: existingItem,
        quantity: item.quantity,
        pricePerOne: item.pricePerOne,
        productImageUrl: item.productImageUrl,
      });
    });

    commit("setDiscount", orderPrice.cartPrice.discount);
    commit("setShippingCost", orderPrice.shippingCost?.costDetail?.cost ?? CHF_ZERO);
  },

  updateCount({commit, state, dispatch}: ActionContext<State, any>, {
    item,
    newCount,
  }: { item: LocalCartItem, newCount: number | string }) {
    const index = _findItem(state.list, item);

    if (index < 0) {
      return;
    }

    /** @type {CartItem} */
    const existingItem = state.list[index];

    const validCount = typeof newCount === "number" && !Number.isNaN(newCount) && newCount > 0 ? newCount : 0;

    // update list
    commit("updateItem", {
      item: existingItem,
      quantity: validCount,
      pricePerOne: existingItem.pricePerOne,
    });

    return dispatch("updatePrices");
  },

  updateShippingCost({commit, state, dispatch}: ActionContext<State, any>, value: string): Promise<void> {
    commit("setShippingZipCode", value);
    if (isValidZipCode(value)) {
      return dispatch("updatePrices");
    }
    return Promise.resolve();
  },
};

export const getters = {
  totalPrice: (state: State) => () => {
    return CartBusinessLogic.calculateOrderTotalWithDiscount(state.list,
      state.discount);
  },

  getCount: (state: State) => () => {
    return state.list.length;
  },
};

function _findItem(list: Array<LocalCartItem>, item: ProductItem) {
  return _.findIndex(list,
    listItem => listItem.product.id === item.product.id &&
      _.isEqual(listItem.configuration, item.configuration));
}

function _updateLocalStorage(item: LocalCartItem) {
  if (process.browser) {
    const localStoreId = generateLocalStoreId(item);
    localStorage.setItem(localStoreId, JSON.stringify(item));
  }
}

function _getApi(_this: Context) {
  if (_this.$graphApi != null) {
    return _this.$graphApi.productRest;
  }
  return new ProductRestApi(_this, new RestApi(_this));
}
